import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
import croppedPhoto from '../images/hero/james.png';
import uncroppedPhoto from '../images/photo.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`pronunciation`}</h2>
    <p>{`Ce-ge`}</p>
    <h2>{`Photos`}</h2>

    <div style={{
      display: 'flex',
      justifyContent: 'space-around',
      textAlign: 'center'
    }}>
  <div>
    <img alt="Cropped photo of James" style={{
          maxHeight: 200,
          display: 'block',
          marginBottom: 4
        }} src={croppedPhoto} />
    <small>Cropped photo of James</small>
  </div>
  <div>
    <img alt="Uncropped photo of James" style={{
          maxHeight: 200,
          display: 'block',
          marginBottom: 4
        }} src={uncroppedPhoto} />
    <small>Uncropped photo of James</small>
  </div>
    </div>
    <h2>{`Bio`}</h2>
    <p>{`Hi, I'm `}<inlineCode parentName="p">{`James`}</inlineCode>{`. I'm an indoorsy, likes with JavaScript, React, React Native and
Front End Programming.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      